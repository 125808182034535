import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
// import { Nav } from 'react-bootstrap';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import LogoImage from '../../../../assets/images/weerp/app-image/environment-setup/splash_screen.png';
import { ProjectTypeContext } from '../../../../ContextHandle'
const ChangeAppLogo = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(507);
        setDocumentation(2);
        setDefaultAppActiveMenu(2);
        document.title = 'Change app logo | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);
    /* eslint-enable */

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Common Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Change app logo</li>
                    </ol>
                </nav>

                <div >
                    <h3 className="pt-5 font-weight-bold" >Change app logo</h3>
                    <ul>
                        <li>Rename your splash to splash1.png then copy/paste into directory location:<b> Project » assets » images</b></li>
                        <li>Re commanded logo size <b>512x512</b></li>
                    </ul>
                    <div className='pt-3'>
                        <img src={LogoImage} className="w-100 rounded" alt="logo.png "></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/push-notification-setup`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Push notification setup</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-app-name`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change App Name</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default ChangeAppLogo;
