import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import './Navbar.css'
import { ProjectTypeContext } from '../../../ContextHandle';
const Navbars = ({ activePage, defaultWebActiveMenu }) => {
    var projectType = useContext(ProjectTypeContext);
    projectType = projectType.projectType;
 
    return (

        <nav id="sidebarMenu" className="navbar  d-lg-block sidebar  bg-white p-3 pb-0">

            <div className="text-dark">
                {/* Getting started  */}
                <ul className='p-0 m-0'>
                    <li className='menu-item mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#started" aria-controls="started" aria-expanded={defaultWebActiveMenu == 0 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold "> Getting Started</h5></button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 0 ? `show` : ''}`} id="started">

                            <ul className="sub-menu" >
                               
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/introduction`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1001 ? "active-hash" : ''}`}
                                    >
                                        Introduction
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/system-requirement`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1002 ? "active-hash" : ''}`}
                                    >
                                        Requirement
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/installation`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1003 ? "active-hash" : ''}`}
                                    >
                                        Installation
                                    </Nav.Link>
                                </li>
                               
                            </ul>
                        </div>
                    </li>
 
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#auth" aria-controls="auth" aria-expanded={defaultWebActiveMenu == 1 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">Authentication</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 1 ? `show` : ''}`} id="auth">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/login`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1005 ? "active-hash" : ''}`}
                                    >
                                        login
                                    </Nav.Link>
                                </li> 
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/signup`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1006 ? "active-hash" : ''}`}
                                    >
                                        Signup
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/forgot-password`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 1007 ? "active-hash" : ''}`}
                                    >
                                        Forgot password
                                    </Nav.Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className='menu-item  mb-0 mt-2'>
                        <button type='button' data-bs-toggle="collapse" data-bs-target="#adminpanel" aria-controls="adminpanel" aria-expanded={defaultWebActiveMenu == 4 ? 'true' : 'false'} aria-label="Toggle navigation"><h5 className="text-dark font-weight-bold ">How to use</h5> </button>
                        <div className={`collapse navbar-collapse ${defaultWebActiveMenu == 4 ? `show` : ''}`} id="adminpanel">
                            <ul className="sub-menu">
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/admin-panel`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2001 ? "active-hash" : ''}`}
                                    >
                                        Admin panel
                                    </Nav.Link>
                                </li>
                                <li className="nav-item">
                                    <Nav.Link
                                        href={`/${projectType}/sender-panel`}
                                        className={`nav-link text-decoration-none sub-menu-text ${activePage === 2002 ? "active-hash" : ''}`}
                                    >
                                        Sender panel
                                    </Nav.Link>
                                </li>
                                
                            </ul>
                        </div>
                    </li>
             

                </ul>

            </div>
        </nav>
    );

};

export default Navbars;

