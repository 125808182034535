import React from 'react'
import { Routes, Route } from "react-router-dom";
import GettingStarted from '../components/wedelivery/Pages/AdminPanel/GettingStarted/GettingStarted';
import Configuration from '../components/wedelivery/Pages/AdminPanel/GettingStarted/Configuration';
import UploadInstall from '../components/wedelivery/Pages/AdminPanel/GettingStarted/UploadInstall';

//authentication
import Login from '../components/wedelivery/Pages/AdminPanel/Auth/Login';
import Signup from '../components/wedelivery/Pages/AdminPanel/Auth/Signup';
import Forgotpassword from '../components/wedelivery/Pages/AdminPanel/Auth/Forgotpassword';

// admin panel
import AdminPanel from '../components/wedelivery/Pages/AdminPanel/AdminPanel';
//admin panel

//shipper panel all page
import SenderPanel from '../components/wedelivery/Pages/AdminPanel/SenderPanel';
//end shipper panel all page

//app documentation  
import Recommendation from '../components/wedelivery/Pages/AppDocumentation/Recommendation';
import Installation from '../components/wedelivery/Pages/AppDocumentation/Installation';
import PrepareFlutterEnvironment from '../components/wedelivery/Pages/AppDocumentation/PrepareFlutterEnvironment';

import ChangeApiServerDetails from '../components/wedelivery/Pages/AppDocumentation/ChangeApiServerDetails';
import ChangeAppLogo from '../components/wedelivery/Pages/AppDocumentation/ChangeAppLogo';
import PushNotificationSetup from '../components/wedelivery/Pages/AppDocumentation/PushNotificationSetup';
import ChangeAppName from '../components/wedelivery/Pages/AppDocumentation/AndroidSetup/ChangeAppName';
import ChangeAndroidLuncherIcon from '../components/wedelivery/Pages/AppDocumentation/AndroidSetup/ChangeAndroidLuncherIcon';
import ChangeAndroidPackageName from '../components/wedelivery/Pages/AppDocumentation/AndroidSetup/ChangeAndroidPackageName';
import GenerateDebugApk from '../components/wedelivery/Pages/AppDocumentation/AndroidSetup/GenerateDebugApk';
import GenerateSignedApk from '../components/wedelivery/Pages/AppDocumentation/AndroidSetup/GenerateSignedApk';
import Preliminaries from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/Preliminaries';
import RegisterYourApp from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/RegisterYourApp';
import RegisterBuildID from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/RegisterBuildID';
import CreateApplicationRecord from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/CreateApplicationRecord';
import ReviewXcodeProject from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/ReviewXcodeProject';
import UpdateAppVersion from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/UpdateAppsVersion';
import AppIcon from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/AppIcon';
import BuildArchive from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/BuildArchive';
import UpdateBuildVersionNumber from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/UpdateBuildVersionNumber';
import CreateAppBuild from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/CreateAppBuild';
import TestFlight from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/TestFlight';
import ReleaseYourAppStore from '../components/wedelivery/Pages/AppDocumentation/IOSsetup/ReleaseYourAppStore';
import AdminPanelRequirement from '../components/wedelivery/Pages/AppDocumentation/Adminpanel/Requirement';
import InstallationProcess from '../components/wedelivery/Pages/AppDocumentation/Adminpanel/InstallactionProcess';

//how to use app
import AppGettingStarted from '../components/wedelivery/Pages/AppDocumentation/GettingStarted';
import AppDriver from '../components/wedelivery/Pages/AppDocumentation/Driver/DriverApp';
//end how to use app

//end app documentation  

import { ProjectList } from '../Enums/ProjectList';
import PageNotFound from '../components/PageNotFound';
import Home from '../components/wedelivery/Home';
import DefaultPage from '../components/wedelivery/Pages/AdminPanel/GettingStarted/DefaultPage';

export default function WedeliveryRoutes({
    activePage, Documentation, defaultAppActiveMenu,
    defaultWebActiveMenu, setDefaultAppActiveMenu, setDocumentation,
    setDefaultWebActiveMenu, setActivePage, sectionRefs, activeSection
}) {


    return (

        <Routes>
            <Route path={`/${ProjectList.WeDelivery}`} element={<Home
                sectionRefs={sectionRefs}
                activeSection={activeSection}
                activePage={activePage}
                documentation={Documentation}
                setDocumentation={setDocumentation}
                defaultAppActiveMenu={defaultAppActiveMenu}
                defaultWebActiveMenu={defaultWebActiveMenu}
            ></Home>}>

                {/* ======================================================= Start web documentation =======================================================*/}

                {/* Getting started  */}
                <Route path='introduction' element={<DefaultPage setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection}></DefaultPage>} />
                <Route path='system-requirement' element={<GettingStarted setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} activeSection={activeSection} ></GettingStarted>}></Route>
                <Route path='configuration' element={<Configuration setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs}  ></Configuration>}></Route>
                <Route path='installation' element={<UploadInstall setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></UploadInstall>}></Route>
                {/* end Getting started  */}

                {/* Authentication */}
                <Route path='login' element={<Login setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                <Route path='signup' element={<Signup setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}> </Route>
                <Route path='forgot-password' element={<Forgotpassword setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} />}></Route>
                {/* End Authentication */}

                {/* Admin panel */}
                <Route path='admin-panel' element={<AdminPanel setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></AdminPanel>}></Route>
                {/* End admin panel */}

                {/* sender panel */}
                <Route path='sender-panel' element={<SenderPanel setDefaultWebActiveMenu={setDefaultWebActiveMenu} setActivePage={setActivePage} sectionRefs={sectionRefs} ></SenderPanel>}></Route>
                {/* End sender panel */}


                {/* ======================================================= end web documentation   =======================================================*/}

                {/* ======================================================= Start APP documentation =======================================================*/}
                {/* app documentation   */}
                <Route index element={<AppGettingStarted setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></AppGettingStarted>}></Route>
                <Route path='app/recommendation' element={<Recommendation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Recommendation>}></Route>
                <Route path='app/installation' element={<Installation setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Installation>}></Route>
                <Route path='app/prepare-flutter-environment' element={<PrepareFlutterEnvironment setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></PrepareFlutterEnvironment>}></Route>
                <Route path='app/change-api-server-details' element={<ChangeApiServerDetails setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeApiServerDetails>}></Route>

                <Route path='app/change-app-logo' element={<ChangeAppLogo setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAppLogo>}></Route>
                <Route path='app/change-app-name' element={<ChangeAppName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ChangeAppName>}  ></Route>
                <Route path='app/push-notification-setup' element={<PushNotificationSetup setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></PushNotificationSetup>}  ></Route>
                <Route path='app/change-android-luncher-icon' element={<ChangeAndroidLuncherIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ChangeAndroidLuncherIcon>}></Route>
                <Route path='app/change-android-package-name' element={<ChangeAndroidPackageName setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}  ></ChangeAndroidPackageName>}></Route>
                <Route path='app/generate-debug-apk' element={<GenerateDebugApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></GenerateDebugApk>}  ></Route>
                <Route path='app/generate-signed-apk' element={<GenerateSignedApk setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></GenerateSignedApk>} ></Route>
                <Route path='app/preliminaries' element={<Preliminaries setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></Preliminaries>} ></Route>
                <Route path='app/register-your-app' element={<RegisterYourApp setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></RegisterYourApp>}  ></Route>
                <Route path='app/register-build-id' element={<RegisterBuildID setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></RegisterBuildID>}></Route>
                <Route path='app/create-application-record' element={<CreateApplicationRecord setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateApplicationRecord>} ></Route>
                <Route path='app/review-xcode-project' element={<ReviewXcodeProject setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></ReviewXcodeProject>}  ></Route>
                <Route path='app/update-apps-version' element={<UpdateAppVersion setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateAppVersion>}  ></Route>
                <Route path='app/add-app-icon' element={<AppIcon setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppIcon>}  ></Route>
                <Route path='app/build-archive' element={<BuildArchive setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></BuildArchive>}  ></Route>
                <Route path='app/update-build-version-number' element={<UpdateBuildVersionNumber setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></UpdateBuildVersionNumber>}  ></Route>
                <Route path='app/create-app-build' element={<CreateAppBuild setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></CreateAppBuild>} ></Route>
                <Route path='app/testflight' element={<TestFlight setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></TestFlight>}  ></Route>
                <Route path='app/release-your-app-store' element={<ReleaseYourAppStore setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu} ></ReleaseYourAppStore>} ></Route>
                <Route path='app/admin-panel-requirement' element={<AdminPanelRequirement setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AdminPanelRequirement>}  ></Route>
                <Route path='app/admin-panel-installation-process' element={<InstallationProcess setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></InstallationProcess>}  ></Route>

                {/*use  how to use app */}
                <Route path='app/driver-app' element={<AppDriver setActivePage={setActivePage} sectionRefs={sectionRefs} setDocumentation={setDocumentation} setDefaultAppActiveMenu={setDefaultAppActiveMenu}></AppDriver>}  ></Route>
                {/*end use  business app */}
                {/* ======================================================= end APP documentation =======================================================*/}
            </Route>
            <Route path={`${ProjectList.WeDelivery}/*`} element={<PageNotFound />} />
        </Routes>

    );
}


