import React, { useEffect, useContext, useRef } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';

import DashboardImg from './../../../../assets/images/wedelivery/web-image/admin/1-dashboard.png';
import OrderMonitoringImg from './../../../../assets/images/wedelivery/web-image/admin/2-order-monitoring.png';
import OrderCreateImg from './../../../../assets/images/wedelivery/web-image/admin/3-order-create.png';
import OrderViewImg from './../../../../assets/images/wedelivery/web-image/admin/4-order-view.png';
import SendersImg from './../../../../assets/images/wedelivery/web-image/admin/5-sender.png';
import ShippingTypesImg from './../../../../assets/images/wedelivery/web-image/admin/6-shipping-types.png';
import BatchImg from './../../../../assets/images/wedelivery/web-image/admin/7-batch.png';
import BatchViewImg from './../../../../assets/images/wedelivery/web-image/admin/7-batch-view.png';
import LoadsImg from './../../../../assets/images/wedelivery/web-image/admin/8-load.png';
import LoadViewImg from './../../../../assets/images/wedelivery/web-image/admin/9-load-view.png';
import DispatcherImg from './../../../../assets/images/wedelivery/web-image/admin/10-dispatcher.png';
import WalletManageImg from './../../../../assets/images/wedelivery/web-image/admin/11-wallet-manage.png';
import LeavetypeImg from './../../../../assets/images/wedelivery/web-image/admin/12-leave-type.png';
import AttendanceImg from './../../../../assets/images/wedelivery/web-image/admin/13-attendance.png';
import OrderStatusProcess from './../../../../assets/images/wedelivery/web-image/admin/14-order-status-process.jpg';
import DeliveryChargeProcess from './../../../../assets/images/wedelivery/web-image/admin/15-delivery-charge-system.jpg';
import { ProjectTypeContext } from '../../../../ContextHandle';
import useScrollSpy from 'react-use-scrollspy';

export default function AdminPanel({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const panelName = projectType.panelName;
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(2001);
        document.title = 'Admin Panel | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className="right-content-box-width">
                <nav >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                    </ol>
                </nav>

                <h4 className="font-weight-bold mt-5  ">Admin Panel</h4>
                <div id='dashboard' ref={pagesectionRefs[0]}>
                    <h5 className="border-top font-weight-bold mt-2 pt-3 text-primary"># Dashboard</h5>
                    <p  >Admin can see total summery in dashboard like -  <b>Total Staffs, total order, total order delivered, total order pending, total income, total expense , total dispatcher, total driver and etc</b>. and also admin can see <b>statements , total sender and etc.</b> </p>
                    <div className='pt-3'>
                        <img src={DashboardImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='orders' ref={pagesectionRefs[1]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Orders monitoring</h5>
                    <p>Admin can manage orders from their panel. and also admin can update their order status. </p>
                    <div className='pt-3'>
                        <img src={OrderMonitoringImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='shippers' ref={pagesectionRefs[2]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Order create</h5>
                    <p >Admin can manage create multiple type orders from their panel. </p>
                    <div className='pt-3'>
                        <img src={OrderCreateImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='payments' ref={pagesectionRefs[3]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Order View</h5>
                    <div className='pt-3'>
                        <div className='mb-2'><b> - Make A Payment</b></div>
                        <p>Admin can view order all details from order view. </p>
                        <img src={OrderViewImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='driver' ref={pagesectionRefs[4]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Sender</h5>
                    <p >Admin can manage sender </p>
                    <div className='pt-3'>
                        <img src={SendersImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>


                <div id='accounts' ref={pagesectionRefs[5]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Shipping types</h5>
                    <p >Admin can manage shipping type delivery charge like - inside and outside </p>
                    <div className='pt-3'>
                        <img src={ShippingTypesImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='wallet' ref={pagesectionRefs[6]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Batch</h5>
                    <p>Admin can manage multiple order batch. </p>
                    <div className='pt-3'>
                        <img src={BatchImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='services' ref={pagesectionRefs[7]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Batch view</h5>
                    <p >Admin can see all batched order list in view page. </p>
                    <div className='pt-3'>
                        <img src={BatchViewImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='vehicle' ref={pagesectionRefs[8]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Load</h5>
                    <div className='pt-3'>
                        <p >Admin can manage load like admin can assign load to driver and driver can update load status. </p>
                        <img src={LoadsImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='media' ref={pagesectionRefs[9]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Load view</h5>
                    <p >All batch list can see when admin will open load view page. and admin can add batch in each load.  </p>
                    <div className='pt-3'>
                        <img src={LoadViewImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='coupon' ref={pagesectionRefs[10]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Dispatcher</h5>
                    <p >Admin can manage Dispatcher and also can assign to staffs. </p>
                    <div className='pt-3'>
                        <img src={DispatcherImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='language' ref={pagesectionRefs[11]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Wallet manage</h5>
                    <p >Admin can manage wallet like - sender wallet recharge and wallet recharge request approval system. </p>
                    <div className='pt-3'>
                        <img src={WalletManageImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>
                <div id='reports' ref={pagesectionRefs[12]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Leave Type</h5>
                    <div className='pt-3'>
                        <p>Admin can manage all staffs leave type. and also can leave assign to staffs. then staffs will available for leave request. </p>
                        <img src={LeavetypeImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='settings' ref={pagesectionRefs[13]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Attendance</h5>
                    <p>Admin can manage staffs attendance check-in and check-out. and admin can generate attendance wise salary.</p>
                    <div className='pt-3'>
                        <img src={AttendanceImg} className="w-100 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='reports' ref={pagesectionRefs[14]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Order status process</h5>
                    <div className='pt-3'>
                        <img src={OrderStatusProcess} className="w-50 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div id='settings' ref={pagesectionRefs[15]}>
                    <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Delivery charge system</h5>
                    <div className='pt-3'>
                        <img src={DeliveryChargeProcess} className="w-50 rounded" alt="account-heads.png"></img>
                    </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/forgot-password`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Forgot Password</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/sender-panel`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Sender panel</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#dashboard' className={activeSection == 0 ? 'active-has' : ""}> Dashboard </a></li>
                    <li><a href='#orders' className={activeSection == 1 ? 'active-has' : ""}> Order monitoring </a></li>
                    <li><a href='#shipper' className={activeSection == 2 ? 'active-has' : ""}> Order create </a></li>
                    <li><a href='#payments' className={activeSection == 3 ? 'active-has' : ""}> Order view </a></li>
                    <li><a href='#driver' className={activeSection == 4 ? 'active-has' : ""}> Sender </a></li>
                    <li><a href='#accounts' className={activeSection == 5 ? 'active-has' : ""}> Shipping types  </a></li>
                    <li><a href='#wallet' className={activeSection == 6 ? 'active-has' : ""}> Batch  </a></li>
                    <li><a href='#services' className={activeSection == 7 ? 'active-has' : ""}> Batch view  </a></li>
                    <li><a href='#vehicle' className={activeSection == 8 ? 'active-has' : ""}> Load  </a></li>
                    <li><a href='#media' className={activeSection == 9 ? 'active-has' : ""}> Load view  </a></li>
                    <li><a href='#coupon' className={activeSection == 10 ? 'active-has' : ""}> Dispatcher  </a></li>
                    <li><a href='#language' className={activeSection == 11 ? 'active-has' : ""}> Wallet manage  </a></li>
                    <li><a href='#reports' className={activeSection == 12 ? 'active-has' : ""}> Leave type  </a></li>
                    <li><a href='#settings' className={activeSection == 13 ? 'active-has' : ""}> Attendance  </a></li>
                    <li><a href='#reports' className={activeSection == 14 ? 'active-has' : ""}> Order status process  </a></li>
                    <li><a href='#settings' className={activeSection == 15 ? 'active-has' : ""}> Delivery charge process  </a></li>
                </ul>
            </div>

        </div>
    )
}
