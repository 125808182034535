import React, { useEffect, useContext, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import Login from '../../../../../assets/images/wedelivery/app-image/driver/1-login.png';
import Profile from '../../../../../assets/images/wedelivery/app-image/driver/2-profile.png';
import Dashboard from '../../../../../assets/images/wedelivery/app-image/driver/3-dashboard.png';
import Orders from '../../../../../assets/images/wedelivery/app-image/driver/4-parcels.png';
import Track from '../../../../../assets/images/wedelivery/app-image/driver/5-tarack.png';
import Load from '../../../../../assets/images/wedelivery/app-image/driver/6-load.png';
import Batch from '../../../../../assets/images/wedelivery/app-image/driver/7-batch.png';
import Menu from '../../../../../assets/images/wedelivery/app-image/driver/8-menu.png';
import ProfileUpdate from '../../../../../assets/images/wedelivery/app-image/driver/9-profile-update.png';
import Language from '../../../../../assets/images/wedelivery/app-image/driver/10-language.png';
 
import { ProjectTypeContext } from '../../../../../ContextHandle';
import useScrollSpy from 'react-use-scrollspy';
const DriverApp = ({ setActivePage, setDocumentation, setDefaultAppActiveMenu }) => {
    /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    const panelName = 'Driver App';
    useEffect(() => {
        setActivePage(105);
        setDocumentation(2);
        document.title = "Driver App | " + projectName;
        setDefaultAppActiveMenu(6);
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });


    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className='right-content-box-width only-mobile-frame'>

                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                    </ol>
                </nav>

                <div id="system-requirements" >

                    <h4 className="font-weight-bold mt-5  ">Driver App</h4>
                    <div className='row border-top'> 
                        <div className='col-md-6' id='dashboard' ref={pagesectionRefs[0]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Login</h5>
                            <p  >Driver can login their account from mobile apps.</p>
                            <div className='pt-3'>
                                <img src={Login} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='accounts' ref={pagesectionRefs[1]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Dashboard</h5>
                            <p  >Driver can see in their home - load, batch Processing, Assigned, Delivered parcels. And driver can track parcel  from their home page. </p>
                            <div className='pt-3'>
                                <img src={Dashboard} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='orders' ref={pagesectionRefs[2]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Profile</h5>
                            <p  >Driver can manage their profile. He can see profile details, change password, change language, privacy and policy and etc.</p>
                            <div className='pt-3'>
                                <img src={Profile} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='invoices' ref={pagesectionRefs[3]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Orders</h5>
                            <p  > Driver can delivered and cancel their assigned parcel from their mobile apps.</p>
                            <div className='pt-3'>
                                <img src={Orders} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='bookingHistory' ref={pagesectionRefs[4]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Track</h5>
                            <p > Driver can track parcel from their track page and he can see all details.</p>
                            <div className='pt-3'>
                                <img src={Track} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>
                      
                        <div className='col-md-6' id='paymentHistory' ref={pagesectionRefs[5]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Load</h5>
                            <p >Driver can pickup and drop batch in assigned load. driver can update load, unload and in-trasit journey.</p>
                            <div className='pt-3'>
                                <img src={Load} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='paymentHistory' ref={pagesectionRefs[6]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Batch</h5>
                            <p >Driver can change batch journey status and driver can see journey logs from load details.</p>
                            <div className='pt-3'>
                                <img src={Batch} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='paymentHistory' ref={pagesectionRefs[7]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Menu</h5>
                            <p >Driver can see lots of pages from their menu - Home, Profile, Load Batch , Order History, Order Track , Language, Logout.</p>
                            <div className='pt-3'>
                                <img src={Menu} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>

                        <div className='col-md-6' id='paymentHistory' ref={pagesectionRefs[8]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Profile update</h5>
                            <p >Driver can manage their profile. He can see profile details, change password, change language, privacy and policy and etc. </p>
                            <div className='pt-3'>
                                <img src={ProfileUpdate} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>


                        <div className='col-md-6' id='paymentHistory' ref={pagesectionRefs[9]}>
                            <h5 className="font-weight-bold mt-2 pt-3 text-primary"># Language</h5>
                            <p >Driver can choose their uses language.</p>
                            <div className='pt-3'>
                                <img src={Language} className="w-75 rounded" alt="Merchant dashboard" />
                            </div>
                        </div>
 
                    </div>
 
                    <div className="d-flex justify-content-between mt-5">
                        <div className="text-start mt-4">
                            {/* <NavLink to={`/${projectType}/app/shipper-app`} className="text-decoration-none">
                                <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                                <h4 className='pagination-title'>Shipper app</h4>
                            </NavLink> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul >
                    <li><a href='#dashboard'      className={activeSection === 0 || activeSection === 1 ? 'active-has' : ""}>Login</a></li>
                    <li><a href='#accounts'       className={activeSection === 1 ? 'active-has' : ""}>Dashboard</a></li>
                    <li><a href='#orders'         className={activeSection === 2 || activeSection === 3 ? 'active-has' : ""}>Profile</a></li>
                    <li><a href='#invoices'       className={activeSection === 3 ? 'active-has' : ""}>Orders</a></li>
                    <li><a href='#bookingHistory' className={activeSection === 4 || activeSection === 5 ? 'active-has' : ""}>Track</a></li> 
                    <li><a href='#paymentHistory' className={activeSection === 4 || activeSection === 5 ? 'active-has' : ""}>Load</a></li>
                    <li><a href='#paymentHistory' className={activeSection === 6 || activeSection === 7 ? 'active-has' : ""}>Batch</a></li>
                    <li><a href='#paymentHistory' className={activeSection === 6 || activeSection === 7 ? 'active-has' : ""}>Menu</a></li>
                    <li><a href='#paymentHistory' className={activeSection === 8 || activeSection === 9 ? 'active-has' : ""}>Profile update</a></li>
                    <li><a href='#paymentHistory' className={activeSection === 8 || activeSection === 9 ? 'active-has' : ""}>Language</a></li>
                </ul>
            </div>

        </div>

    );

};

export default DriverApp;
