
import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import DashboardImg from '../../../../assets/images/wedelivery/web-image/sender/1-dashboard.png';
import OrderImg from '../../../../assets/images/wedelivery/web-image/sender/2-orders.png';
import OrderCreateImg from '../../../../assets/images/wedelivery/web-image/sender/3-order-create.png';
import OrderViewImg from '../../../../assets/images/wedelivery/web-image/sender/4-order-view.png';
import OrderPlacedImg from '../../../../assets/images/wedelivery/web-image/sender/5-order-placed.png';
import LabelImg from '../../../../assets/images/wedelivery/web-image/sender/6-label.png'; 
import CustomerListImg from '../../../../assets/images/wedelivery/web-image/sender/7-customer-list.png'; 
import StatsticsImg from '../../../../assets/images/wedelivery/web-image/sender/8-statastics.png'; 
import ReportsImg from '../../../../assets/images/wedelivery/web-image/sender/9-reports.png'; 
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function SenderPanel({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName = 'Sender panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(2002);
        document.title = 'Sender panel | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                    </ol>
                </nav>
                <h4 className="font-weight-bold mt-5  ">Sender Panel</h4>
                <div  >
                    <div id='dashboard' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Dashboard</h5>
                        <p className=" pt-3">Sender can see their total summery in dashboard like - <b>Total order, Total invoice, Total Processing orders, Total Delivered, Total Failure and etc.</b> </p>
                        <div className='pt-3'>
                            <img src={DashboardImg} className="w-100 rounded" alt="dashboard.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='accounts' ref={pagesectionRefs[1]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Orders</h5>
                        <p  >Sender can see their order list in their order page. </p>
                        <div className='pt-3'>
                            <img src={OrderImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='orders' ref={pagesectionRefs[2]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Order create</h5>
                        <p >Sender can create order easily from their panel.</p>
                        <div className='pt-3'>
                            <img src={OrderCreateImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='invoice' ref={pagesectionRefs[3]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Order view</h5>
                        <p  >Sender can see all order information in view page. </p>
                        <div className='pt-3'>
                            <img src={OrderViewImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='bookingHistory' ref={pagesectionRefs[4]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Order placed</h5>
                        <p  >Sender can see order direction and order processing information from placed page. </p>
                        <div className='pt-3'>
                            <img src={OrderPlacedImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='makeapayment' ref={pagesectionRefs[5]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Label</h5>
                        <p  >Sender can print order label. </p>
                        <div className='pt-3'>
                            <img src={LabelImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='paymenthistory' ref={pagesectionRefs[6]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Customer list</h5>
                        <p  >Sender can see their customer list. </p>
                        <div className='pt-3'>
                            <img src={CustomerListImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='statastics' ref={pagesectionRefs[6]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Statastics</h5>
                        <p  >Sender can see their all summery in statastics page. </p>
                        <div className='pt-3'>
                            <img src={StatsticsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='reports' ref={pagesectionRefs[6]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Reports</h5>
                        <p  >Sender can see their reports like - order status reports, total summery reports, account transaction, statements and etc. </p>
                        <div className='pt-3'>
                            <img src={ReportsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin-panel`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Admin panel</h4>
                        </NavLink>
                    </div>
                    
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#dashboard'      className={activeSection == '0' ? 'active-has' : ""}> Dashboard </a></li> 
                    <li><a href='#accounts'       className={activeSection == '1' ? 'active-has' : ""}> Orders </a></li> 
                    <li><a href='#orders'         className={activeSection == '2' ? 'active-has' : ""}> Order create </a></li> 
                    <li><a href='#invoice'        className={activeSection == '3' ? 'active-has' : ""}> Order view </a></li> 
                    <li><a href='#bookingHistory' className={activeSection == '4' ? 'active-has' : ""}> Order placed </a></li> 
                    <li><a href='#makeapayment'   className={activeSection == '5' ? 'active-has' : ""}> Label</a></li> 
                    <li><a href='#paymenthistory' className={activeSection == '6' ? 'active-has' : ""}> Customer list </a></li> 
                    <li><a href='#statastics'     className={activeSection == '7' ? 'active-has' : ""}> Statastics </a></li> 
                    <li><a href='#reports'        className={activeSection == '8' ? 'active-has' : ""}> Reports </a></li> 
                </ul>
            </div>
        </div>
    )
}

