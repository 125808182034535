import React, { useEffect, useContext,useRef } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import Dashboard from './../../../../assets/images/wemover/web-image/driver/dashboard.png';
import Account from './../../../../assets/images/wemover/web-image/driver/accounts.png';
import Orders from './../../../../assets/images/wemover/web-image/driver/orders.png';
import Orderhistory from './../../../../assets/images/wemover/web-image/driver/order-history.png';
import Invoice from './../../../../assets/images/wemover/web-image/driver/invoice-history.png';
import BookingHistory from './../../../../assets/images/wemover/web-image/driver/booking-history.png';
import MakeAPayment from './../../../../assets/images/wemover/web-image/driver/make-a-payment.png';
import Payment from './../../../../assets/images/wemover/web-image/driver/payment-history.png';
import { ProjectTypeContext } from '../../../../ContextHandle';
import useScrollSpy from 'react-use-scrollspy';
export default function DriverPanel({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(2003);
        document.title = 'Driver Panel | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);
    /* eslint-enable */
  
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
 
    return (
        <div className='d-flex justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                        <li className="breadcrumb-item" aria-current="page">Driver Panel</li>
                    </ol>
                </nav>
                <h4 className="font-weight-bold mt-5  ">Driver Panel</h4>
                <div id="dashboard" ref={pagesectionRefs[0]}>
                    <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Dashboard</h5>
                    <p>Driver can see total summery in dashboard like - <b>Total orders, Total Invoices, Total booking history, Total payment, Today orders, Today Payment and etc.</b> </p>
                    <div className='pt-3'>
                        <img src={Dashboard} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>

                <div id="account" ref={pagesectionRefs[1]}>
                    <h5 className="font-weight-bold mt-2 pt-2 text-primary"># Accounts</h5>
                    <p>Multiple account can manage driver and driver can received payment in their any account. </p>
                    <div className='pt-3'>
                        <img src={Account} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>
                <div id="orders" ref={pagesectionRefs[2]}>
                    <h5 className="font-weight-bold mt-2 pt-2 text-primary"># Orders</h5>
                    <p>Last 30 days of sales analytics charts will be displayed. also Last 30 days of POS sales analytics charts will be displayed. A list of recent sales and POS sales list will be displayed. </p>
                    <div className='pt-3'>
                        <img src={Orders} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>
                <div id="orderhistory" ref={pagesectionRefs[3]}>
                    <h5 className="font-weight-bold mt-2 pt-2 text-primary"># Order History</h5>
                    <p>After complete order then driver can see their order completed history.</p>
                    <div className='pt-3'>
                        <img src={Orderhistory} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>
                <div id="invoice" ref={pagesectionRefs[4]}>
                    <h5 className="font-weight-bold mt-2 pt-2 text-primary"># Invoice</h5>
                    <p>When driver will complete order then driver can see their completed order invoice list. </p>
                    <div className='pt-3'>
                        <img src={Invoice} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>
                <div id="bookinghistory" ref={pagesectionRefs[5]}>
                    <h5 className="font-weight-bold mt-2 pt-2 text-primary"># Booking history</h5>
                    <p>All accepted order booking history driver can see from booking history section. </p>
                    <div className='pt-3'>
                        <img src={BookingHistory} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>
                <div id="payment" ref={pagesectionRefs[6]}>
                    <h5 className="font-weight-bold mt-2 pt-2 text-primary"># Payment</h5> 
                    <div className='pt-3'>
                        <div><b>- Make a payment</b></div>
                        <p>When driver will complete order and then driver will received payment from shipper. Now driver need to send amount to courier. Now driver will send amount from make a payment. </p>
                        <img src={MakeAPayment} className="w-100 rounded" alt="Make-a-payment.png"></img>
                        <div><b>- Payment history</b></div>
                        <p>
                            Driver all payment history can see from payment history section.
                        </p>
                        <img src={Payment} className="w-100 rounded" alt="Dashboard"></img>
                    </div>
                </div>
 
                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/shipper-panel`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Shipper panel</h4>
                        </NavLink>
                    </div>
                     
                </div>
            </div>

            <div className='right-menu'>
                <ul>
                    <li><a href='#dashboard'      className={activeSection === 0 ? 'active-has' : ""}>Dashboard </a></li> 
                    <li><a href='#account'        className={activeSection === 1 ? 'active-has' : ""}>Account </a></li> 
                    <li><a href='#orders'         className={activeSection === 2 ? 'active-has' : ""}>Orders </a></li> 
                    <li><a href='#orderhistory'   className={activeSection === 3 ? 'active-has' : ""}>Orders history </a></li> 
                    <li><a href='#invoice'        className={activeSection === 4 ? 'active-has' : ""}>Invoice history </a></li> 
                    <li><a href='#bookinghistory' className={activeSection === 5 ? 'active-has' : ""}>Booking history </a></li> 
                    <li><a href='#payment'        className={activeSection === 6 ? 'active-has' : ""}>Payment </a></li> 
                </ul>
            </div>

        </div>
    )
}
