import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
// import { Nav } from 'react-bootstrap';
import './../../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import useScrollSpy from 'react-use-scrollspy';
import database_1 from '../../../../../assets/images/wedelivery/web-image/config/database_1.png';
import database_2 from '../../../../../assets/images/wedelivery/web-image/config/database_2.png';
import DatabasePrivileges from '../../../../../assets/images/wedelivery/web-image/config/privleges.png';
import upload_file_1 from '../../../../../assets/images/wedelivery/web-image/config/upload_file_1.png';
import filemanager from '../../../../../assets/images/wedelivery/web-image/config/file_manager.png';
import Install1 from '../../../../../assets/images/wedelivery/web-image/install/install_1.png';
import Install2 from '../../../../../assets/images/wedelivery/web-image/install/install_2.png';
import Install3 from '../../../../../assets/images/wedelivery/web-image/install/install_3.png';
import Install4 from '../../../../../assets/images/wedelivery/web-image/install/install_4.png';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const UploadInstall = ({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) => {
 /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1003);
        document.title = 'Installation | ' + projectName + ' web';
        setDefaultWebActiveMenu('0');
    }, [setActivePage, projectName]);

    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements" className='pt-5 right-content-box-width' ref={sectionRefs[3]} >
                <nav id='createdb' ref={pagesectionRefs[0]} >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Getting Started</li>
                        <li className="breadcrumb-item active" aria-current="page">Installation</li>
                    </ol>
                </nav>

                <h4 className="pt-5 font-weight-bold">Installation Step</h4>
                <b>Step 01: Login to your cpanel and go to {`>`} MySQL® Database Wizard</b>
                <h4 className='font-weight-bold mt-2'>Create database</h4>
                <b>Step 02:Create Database</b>
                <div className='pt-3'>
                    <img src={database_1} className="w-100 rounded" alt="Database configuration"></img>
                </div>
                <div id='dbuser-todb' ref={pagesectionRefs[1]} >
                    <h4 className='font-weight-bold mt-3'>Create Database Users</h4>
                    <b>Step 03:</b> Create Database Users, do not forget to keep your database user password, we will need this while we run installation wizard.Create Database Users, do not forget to keep your database user password, we will need this while we run installation wizard.
                    <h4 className='font-weight-bold mt-3'>Add user to the database</h4>
                    <b>Step 04:</b> Add user to the database. check all privileges and click make changes. then click a next step Database create is done, keep database user, database name, database password in a note.
                    <div className='pt-3'>
                        <img src={database_2} className="w-100 rounded" alt="Database configuration"></img>
                    </div>

                    <p className='pt-3' >After add user to database , click on all privileges  and then click on make changes button.</p>
                    <div className='pt-3'>
                        <img src={DatabasePrivileges} className="w-100 rounded" alt="Database configuration"></img>
                    </div>
                    <p className='pt-3 font-weight-bold'>Then copy your database user name ,  database name and database password. </p>
                </div>

                <div id='uploadfile' ref={pagesectionRefs[2]} >
                    <h4 className="  pt-5 font-weight-bold">Upload File</h4>

                    <p><b>Step 5:</b> Download Main_Files.zip from CodeCanyon</p>
                    <p>  <b>Step 6:</b> Extract Main_Files.zip</p>
                    <p><b>Step 7:</b> Go to inside "SourceCode" folder and make zip all files & folders.</p>
                    <p><b>Step 8:</b> Upload zip file to your server public_html or www or root directory.</p>

                    <div className='pt-3'>
                        <img src={filemanager} className="w-100 rounded" alt="Upload File"></img>
                    </div>
                    <div className='pt-3'>
                        <img src={upload_file_1} className="w-100 rounded" alt="Upload File"></img>
                    </div>
                </div>

                <div id='openbrowserurl' ref={pagesectionRefs[3]} >
                    <h4 className='font-weight-bold mt-3'>Open Browse URL</h4>
                    <b>Step 08:</b> Open browser and hit you server URL. Example: <b>https://example.com/</b>
                    <div className='pt-3'>
                        <img src={Install1} className="w-100 rounded" alt="Install"></img>
                    </div>
                    <div className='pt-3'>
                        <img src={Install2} className="w-100 rounded" alt="requirement"></img>
                    </div>
                </div>

                <div id='dbconnection' ref={pagesectionRefs[4]} >

                    <p className='my-3'><b className='pr-2'>Step 09:</b>
                        Your database connection details:
                        <ul>
                            <li> <b>Database Host:</b> your hosting name, e.g localhost</li>
                            <li> <b>Database User:</b> the username that you have created with all privileges.</li>
                            <li> <b>Password:</b> that user password.</li>
                            <li> <b>Database Name:</b> your database name for e.g <b>wemaxdevs_wedelivery_db</b>. </li>
                        </ul>
                        If all the requirements are fulfilled then click on Next.
                    </p>
                    <div className='pt-3'>
                        <img src={Install3} className="w-100 rounded" alt="database information"></img>
                    </div>
                </div>

                <div id='administration' ref={pagesectionRefs[5]} >
                    <p className='my-3'>
                        <b>Step 10:</b>  Your account details for administration:
                        <ul>
                            <li><b>First Name:</b> that will be the Administrator first name</li>
                            <li><b>Last Name:</b> that will be the Administrator last name</li>
                            <li><b>Email:</b> email for login and you can't change this email.</li>
                            <li><b>Password:</b> password for admin login. </li>
                            <li><b>Purchase code:</b> your need to fillup CodeCanyon item purchase code. </li>
                            After successful installation, system will redirect you to the home page.
                        </ul>
                    </p>
                    <div className='pt-3'>
                        <img src={Install4} className="w-100 rounded" alt="Administration information"></img>
                    </div>
                    <div className='alert alert-info my-4'>
                        <i className='fa fa-circle-info'></i> <b> INFO:</b><br />
                        Given Administrator credentials will be serve as the Super Admin email and password for accessing admin dashboard
                    </div>
                </div>
        

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/system-requirement`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Requirement</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/login`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Login</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='right-menu'>
                <ul >
                    <li><a href='#createdb' className={activeSection === 0 ? 'active-has' : ""}>Create Database</a></li>
                    <li><a href='#dbuser-todb' className={activeSection === 1 ? 'active-has' : ""}>Create Database user & add user to database</a></li>
                    <li><a href='#uploadfile' className={activeSection === 2 ? 'active-has' : ""}>Upload File</a></li>
                    <li><a href='#openbrowserurl' className={activeSection === 3 ? 'active-has' : ""}>Open Browser URL</a></li>
                    <li><a href='#dbconnection' className={activeSection === 4 ? 'active-has' : ""}>Database Connection</a></li>
                    <li><a href='#administration' className={activeSection === 5 ? 'active-has' : ""}>Administration account details  </a></li>
                 </ul>
            </div>

        </div>

    );

};

export default UploadInstall;
