import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './../../../../../assets/images/codestyle.css';
// import ScrollToTop from '../../../../Shared/ScrollToTop';
import { useLocation } from 'react-router-dom';
import { ProjectTypeContext } from '../../../../../ContextHandle';
const GettingStarted = ({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1002);
        document.title = 'Requirement | ' + projectName + ' web';
        setDefaultWebActiveMenu('0');
    }, [projectName]);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 1);
    }, [pathname]);

    return (
        <div className='d-flex flex-column justify-content-between doc-page' ref={sectionRefs[1]}>

            <div className=" right-content-box-width">
                <div id="system-requirements"  >
                    <nav  >
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                            <li className="breadcrumb-item" aria-current="page">Getting Started</li>
                            <li className="breadcrumb-item active" aria-current="page">Requirement</li>
                        </ol>
                    </nav>
                    <h4 className="pt-3">Requirements</h4>
                    <p className="font-weight-bold">Most current web servers with PHP &amp; MySQL installed will be capable of
                        running We Starter.</p>
                    <p className="font-weight-bold">Minimum Requirements</p>
                    <ul>
                        <li>Linux or Windows OS</li>
                        <li>PHP Version 8.1+</li>
                        <li>MySQLi Support</li>
                        <li>Rewrite Module (Apache)</li>
                        <li>BCMath PHP Extension</li>
                        <li>Ctype PHP Extension</li>
                        <li>Fileinfo PHP extension</li>
                        <li>JSON PHP Extension</li>
                        <li>Mbstring PHP Extension</li>
                        <li>OpenSSL PHP Extension</li>
                        <li>PDO PHP Extension</li>
                        <li>Tokenizer PHP Extension</li>
                        <li>XML PHP Extension</li>
                    </ul>
                    <div className="d-flex justify-content-between border-top mt-5">
                        <div className="text-start mt-4">
                            <NavLink to={`/${projectType}/introduction`} className="text-decoration-none">
                                <p className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></p>
                                <h4 className='pagination-title'>Introduction</h4>
                            </NavLink>
                        </div>
                        <div className="text-end my-2">
                            <NavLink to={`/${projectType}/installation`} className="text-decoration-none">
                                <p id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></p>
                                <h4 className='pagination-title'>Installation</h4>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );

};

export default GettingStarted;
