import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../../../../Shared/ScrollToTop';
import Thumbnail from '../../../../../assets/item-logo/wedelivery/wedelivery-inline-preview.png'
import { ProjectTypeContext } from '../../../../../ContextHandle'
const DefaultPage = ({ setActivePage, sectionRefs, activeSection, setDefaultWebActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(1001);
        document.title = 'Introduction | ' + projectName + ' web';
        setDefaultWebActiveMenu('0');
    }, [projectName,setActivePage,
        setDefaultWebActiveMenu]);

    return (
        <div className='d-flex   justify-content-between doc-page' ref={sectionRefs[0]}>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item"  >Getting Started</li>
                        <li className="breadcrumb-item active" aria-current="page">Introduction</li>
                    </ol>
                </nav>
                <img src={Thumbnail} alt='Thumbnail' className='mt-5 project-thumbnail' />
                <h3 className="pt-5">Web Documentation</h3>
                <h4 className="pt-3">We Delivery - Parcel delivery courier management Logistics support CMS with web</h4>
                <p className='pt-3'>Thank you so much for purchasing our item from codecanyon.</p>
                <p><b>WeDelivery</b> is a complete goods delivery management solution for the most common business platforms. It has a lot of build-in futures which are needed for every goods moving management site. It is designed for those who want to start their goods delivery website. There are many delivery scripts, many of them are very expensive, and have very complex interfaces for admin and user. <b>WeDelivery</b> is designed with user friendly interface and simple interface. Any non-technical person can use this software.</p>
                <hr />
                <p><b>Version:</b> 1.0</p>
                <p><b>Author:</b> WemaxDevs</p>
                <p><b>Created:</b> Dec 2024</p>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <p className="text-secondary"><i className="bi bi-caret-left" /> </p>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/system-requirement`} className="text-decoration-none">
                            <p href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></p>
                            <h4 className='pagination-title'>Requirement</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DefaultPage;
