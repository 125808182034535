
import React, { useEffect, useRef, useContext } from 'react'
import ScrollToTop from '../../../Shared/ScrollToTop';
import { NavLink } from 'react-router-dom';
import ShipperDashboardImg from '../../../../assets/images/wemover/web-image/shipper/shipper-dashboard.png';
import AccountsImg from '../../../../assets/images/wemover/web-image/shipper/accounts.png';
import OrdersImg from '../../../../assets/images/wemover/web-image/shipper/orders.png';
import InvoiceImg from '../../../../assets/images/wemover/web-image/shipper/invoice.png';
import BookingHistoryImg from '../../../../assets/images/wemover/web-image/shipper/booking-history.png';
import MakeAPaymentImg from '../../../../assets/images/wemover/web-image/shipper/make-a-payment.png'; 
import PaymentHistoryImg from '../../../../assets/images/wemover/web-image/shipper/payment-history.png'; 
import useScrollSpy from 'react-use-scrollspy';
import { ProjectTypeContext } from '../../../../ContextHandle';
export default function ShipperPanel({ setActivePage, sectionRefs, setDefaultWebActiveMenu }) {
 
    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    const panelName = 'Shipper panel';
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(2002);
        document.title = 'Shipper panel | ' + projectName + ' web';
        setDefaultWebActiveMenu(4);
    }, [projectName]);
    /* eslint-enable */
    return (
        <div className='d-flex  justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div className=" right-content-box-width">
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}/introduction`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item active" aria-current="page">How To Use</li>
                        <li className="breadcrumb-item" aria-current="page">{panelName}</li>
                    </ol>
                </nav>
                <h4 className="font-weight-bold mt-5  ">Shipper Panel</h4>
                <div  >
                    <div id='dashboard' ref={pagesectionRefs[0]}>
                        <h5 className="border-top font-weight-bold mt-2 pt-2 text-primary"># Dashboard</h5>
                        <p className=" pt-3">Shipper can see their total summer in dashboard like - <b>Total order, Total invoice, Total booking history, Total payment, Today orders, Pie chart and etc.</b> </p>
                        <div className='pt-3'>
                            <img src={ShipperDashboardImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div  > 
                    <div id='accounts' ref={pagesectionRefs[1]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Accounts</h5>
                        <p  >Shipper can manage their multiple bank or cash account from their panel. </p>
                        <div className='pt-3'>
                            <img src={AccountsImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div  > 
                    <div id='orders' ref={pagesectionRefs[2]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Orders</h5>
                        <p  >Easily shipper can manage order from their panel. </p>
                        <div className='pt-3'>
                            <img src={OrdersImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='invoice' ref={pagesectionRefs[3]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Invoice</h5>
                        <p  >After complete their order then shipper can see their invoice list. </p>
                        <div className='pt-3'>
                            <img src={InvoiceImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='bookingHistory' ref={pagesectionRefs[4]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Booking history</h5>
                        <p  >Shipper can see their order history with status process. </p>
                        <div className='pt-3'>
                            <img src={BookingHistoryImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='makeapayment' ref={pagesectionRefs[5]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Make a payment</h5>
                        <p  >After complete order then shipper will payment to driver. </p>
                        <div className='pt-3'>
                            <img src={MakeAPaymentImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>
                <div> 
                    <div id='paymenthistory' ref={pagesectionRefs[6]}>
                        <h5 className=" font-weight-bold mt-2 pt-2 text-primary"># Payment history</h5>
                        <p  >Shipper can see their all payment history. </p>
                        <div className='pt-3'>
                            <img src={PaymentHistoryImg} className="w-100 rounded" alt="account-heads.png"></img>
                        </div>
                    </div>  
                </div>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/admin-panel`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Admin panel</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/driver-panel`} className="text-decoration-none">
                            <a href="#login" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Driver panel</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul>
                    <li><a href='#dashboard' className={activeSection === 0 ? 'active-has' : ""}>Dashboard </a></li> 
                    <li><a href='#accounts' className={activeSection === 1 ? 'active-has' : ""}>Accounts </a></li> 
                    <li><a href='#orders' className={activeSection === 2 ? 'active-has' : ""}>Orders </a></li> 
                    <li><a href='#invoice' className={activeSection === 3 ? 'active-has' : ""}>Invoice </a></li> 
                    <li><a href='#bookingHistory' className={activeSection === 4 ? 'active-has' : ""}>Booking history </a></li> 
                    <li><a href='#makeapayment' className={activeSection === 5 ? 'active-has' : ""}>Make a payment </a></li> 
                    <li><a href='#paymenthistory' className={activeSection === 6 ? 'active-has' : ""}>Payment history </a></li> 
                </ul>
            </div>
        </div>
    )
}

