import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

export default function PageNotFound() {
  useEffect(() => {
    document.title = 'Page not found | WemaxDevs';
  });
  return (
    <div className='doc-home-page'>
      <section className="container-fluid bg-light key-features  " style={{ height: '100vh' }}>
        <div className="row " style={{ height: '100vh' }}>
          <div className="col-12  m-auto text-center">
            <h1 className='font-wight-bold text-center' style={{ fontSize: '70px', fontWeight: '900' }}><span className='badge badge-outline-danger border-redius-70px'  >404</span></h1>
            <div className="mb-2" style={{ fontSize: '20px' }}><span className="text-danger"  >Opps! Something went wrong.</span></div>
            <h1 className='display-lg-1' style={{ fontSize: '50px', fontWeight: '900' }}>Page not found.</h1>
            <p>The page you are looking for has not been found on our server.</p>
            <footer className="container-fluid">
              <NavLink to="/" className="btn btn-primary rounded-pill buy mt-2" style={{ display: 'inline-block', color: '#ffff!important' }}> <i className='fa fa-home me-2'></i> Go to Home</NavLink>
            </footer> 
          </div>
        </div>
      </section>

    </div>
  )
}
