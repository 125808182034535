import React, { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import '../../../../assets/images/codestyle.css';
import useScrollSpy from 'react-use-scrollspy';
import ScrollToTop from '../../../Shared/ScrollToTop';
import Img1 from '../../../../assets/images/wemover/app-image/push-notification/firebase-1.png';
import Img2 from '../../../../assets/images/wemover/app-image/push-notification/firebase-2.png';
import Img3 from '../../../../assets/images/wemover/app-image/push-notification/firebase-3.png';
import Img4 from '../../../../assets/images/wemover/app-image/push-notification/firebase-4.png';
import Img5 from '../../../../assets/images/app-image/firebase-notification/google-serverjson6.png'; 
import Img6 from '../../../../assets/images/wemover/app-image/push-notification/firebase-5.jpeg';
import WebImg1 from '../../../../assets/images/wemover/app-image/push-notification/web-firebase-1.png';
import WebImg2 from '../../../../assets/images/wemover/app-image/push-notification/web-firebase-2.png';
import WebImg3 from '../../../../assets/images/wemover/app-image/push-notification/web-firebase-3.png';
import WebImg4 from '../../../../assets/images/wemover/app-image/push-notification/web-firebase-4.png';
import WebImg5 from '../../../../assets/images/wemover/app-image/push-notification/web-firebase-5.png';
import WebImg6 from '../../../../assets/images/wemover/app-image/push-notification/web-firebase-6.png';
import { ProjectTypeContext } from '../../../../ContextHandle'
const GoogleFirebaseSetup = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
     /* eslint-disable */
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    useEffect(() => {
        setActivePage(50777);
        setDocumentation(2);
        setDefaultAppActiveMenu(2);
        document.title = 'Push Notification Setup | ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);


    const pagesectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: pagesectionRefs,
        offsetPx: -80,
    });



    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Common Setup</li>
                        <li className="breadcrumb-item active" aria-current="page">Push Notification Setup</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold" >Push Notification Setup</h3>
                <p className='pt-3'><b>To use firebase follow the procedure which are mentioned below</b></p>
                <h3 className="pt-2 font-weight-bold"  >Android and IOS Setup</h3>
                <ol>
                    <li id='step1' className='py-3'  ref={pagesectionRefs[0]} >
                        Go to this URL to create project <Nav.Link rel="noreferrer" className='d-inline-block p-0 text-primary' href="https://console.firebase.google.com/u/0/" target="_blank" >https://console.firebase.google.com/u/0/</Nav.Link> If you already have a project then continue with that.

                        <div className='pt-3'>
                            <img src={Img1} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li   className='py-3'  > Now go to project settings
                        <div className='pt-3'>
                            <img src={Img2} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li  className='py-3'  > Go to general then create your android app <b>Shipper or driver</b>. Then download <code>google-services.json</code> file.
                        <div className='pt-3'>
                            <img src={Img3} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li   className='py-3'  >Open your project go to <code className='font-weight-bold'> Project {`>`} android {`>`} app {`>`} google-services.json</code> replace or create file.
                        <div className='pt-3'> 
                             <img src={Img5} className="w-100 rounded" alt="Home"></img> 
                        </div>
                    </li>
                    <li  className='py-3'  > Go to general then create your IOS app <b>Shipper or driver</b>. Then download <code>GoogleService-Info.plist</code> file.
                        <div className='pt-3'>
                            <img src={Img4} className="w-100 rounded" alt="Home"></img>
                        </div>
                        
                    </li>
                    <li className='py-3'>Open your project go to <code className='font-weight-bold'> Project {`>`} ios {`>`} Runner {`>`} GoogleService-info.plist</code> replace or create file.
                        <div className='pt-3'>
                            <img src={Img6} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                  
                </ol>
                <h3 className="pt-2 font-weight-bold" >Web Setup</h3>
                <ol >
                    
                    <li id='step2' className='py-3' ref={pagesectionRefs[1]}> Now go to project settings to get your firebaseConfig code , now copy this code.
                        <div className='pt-3'>
                            <img src={WebImg1} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li   className='py-3'  > Replace this firebaseConfig code in this page: <code className='font-weight-bold'> project {`>`} views {`>`} backend {`>`} partials {`>`} footer.blade.php </code>
                        <div className='pt-3'>
                            <img src={WebImg2} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li   className='py-3'  > Go to <b>service accounts</b> and generate your private key and <b>download json file</b> and open this json file and copy all code from this json file.
                        <div className='pt-3'>
                            <img src={WebImg3} className="w-100 rounded" alt="Home"></img>
                        </div>
                    </li>
                    <li   className='py-3' >Now open this <b>firebase private key json file </b>: <code className='font-weight-bold'> project {`>`} config {`>`} FirebasePrivateKey.json </code>  <b> now replace this all code.</b>
                        <div className='pt-3'> 
                             <img src={WebImg4} className="w-100 rounded" alt="Home"></img> 
                        </div>
                    </li>
                    <li className='py-3'  > Go to <b>general</b> and then copy this <code className='font-weight-bold'>Project ID</code>
                        <div className='pt-3' id='step5'>
                            <img src={WebImg5} className="w-100 rounded" alt="Home"></img>
                        </div>
                        
                    </li>
                    <li className='py-3'  >Open environment file from project : <code className='font-weight-bold'>Project {`>`} .env</code> then replece <b>FCM_PROJECT_ID =</b> <code className='font-weight-bold'>your project ID</code>
                        <div className='pt-3' id='step5'>
                            <img src={WebImg6} className="w-100 rounded" alt="Home"></img>
                        </div>
                        
                    </li>
                </ol>
                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/change-api-server-details`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Change api server details</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-app-logo`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change app logo</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className='right-menu'>
                <ul >
                    <li><a href='#step1' className={activeSection == 0 ? 'active-has' : ""}>Android and ios setup</a></li>
                    <li><a href='#step2' className={activeSection == 1 ? 'active-has' : ""}>Web setup</a></li> 
                </ul>
            </div>

        </div>

    );

};

export default GoogleFirebaseSetup;
