import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../../assets/images/codestyle.css';
import ScrollToTop from '../../../Shared/ScrollToTop';
import { ProjectTypeContext } from '../../../../ContextHandle'
const PrepareFlutterEnvironment = ({ setActivePage, sectionRefs, setDocumentation, setDefaultAppActiveMenu }) => {
    var projectType = useContext(ProjectTypeContext);
    const projectName = projectType.projectName;
    projectType = projectType.projectType;
    /* eslint-disable */
    useEffect(() => {
        setActivePage(503);
        setDocumentation(2);
        setDefaultAppActiveMenu(1);
        document.title = 'Prepare Flutter Environment |  ' + projectName;
    }, [setActivePage, setDocumentation, setDefaultAppActiveMenu, projectName]);
    /* eslint-enable */

    return (
        <div className='d-flex   justify-content-between doc-page'>
            <ScrollToTop></ScrollToTop>
            <div id="system-requirements  " className='pt-5 right-content-box-width' >
                <nav  >
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/${projectType}`}><i className='fa fa-home'></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">App Basics</li>
                        <li className="breadcrumb-item active" aria-current="page">Prepare Flutter Environment</li>
                    </ol>
                </nav>

                <h3 className="pt-5 font-weight-bold">Flutter Environment</h3>

                <ul className='py-3'>
                    <li>
                        <p>Flutter Installation on Windows</p>
                        <iframe width="850" height="478" src="https://www.youtube.com/embed/fDnqXmLSqtg" title="2. Full Installation of Flutter on Windows - Your First Flutter App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </li>
                    <li className='mt-3'>
                        <p>Flutter Installation on Mac</p>
                        <iframe width="850" height="478" src="https://www.youtube.com/embed/Dak3912YhYk" title="How to Install Flutter on macOS 2021| Install Xcode, Android Studio,VS code" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </li>
                    <li className='mt-3'>
                        <p>If you have updated you mac to Catalina from mojavi or earlier version then you may fetch some issues.Please follow bellow video to fix it</p>
                        <iframe width="850" height="478" src="https://www.youtube.com/embed/ZwUGHiroGBo" title="Flutter MAC Catalina installation and first app | Resolving all bugs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </li>
                </ul>

                <div className="d-flex justify-content-between border-top mt-5">
                    <div className="text-start mt-4">
                        <NavLink to={`/${projectType}/app/installation`} className="text-decoration-none">
                            <a href='/' className="mb-0 text-decoration-none text-secondary">Previous <i className="bi bi-caret-left" /></a>
                            <h4 className='pagination-title'>Installation</h4>
                        </NavLink>
                    </div>
                    <div className="text-end my-2">
                        <NavLink to={`/${projectType}/app/change-api-server-details`} className="text-decoration-none">
                            <a href="/#" id="quickStartGuide" className="mb-0 text-decoration-none text-secondary">Next <i className="bi bi-caret-right" /></a>
                            <h4 className='pagination-title'>Change api server details</h4>
                        </NavLink>
                    </div>
                </div>
            </div>

        </div>

    );

};

export default PrepareFlutterEnvironment;
